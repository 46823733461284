.referencePanel {
  color: #fff;
  background-color: #393d50;
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  width: 25%;
  height: 100vh;
  overflow: scroll;
}

.sidePanelOptions {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.referencesContainer {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  height: calc(100vh - 65px - 100px);
  overflow: scroll;
}

.sidePanelHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
}

.referenceIconContainer {
  background-color: #7a6a8d;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.referenceIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 22px;
  float: right;
  height: 16px;
  width: 16px;
}

.referenceContentUploader {
  background-color: #464b5b;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  vertical-align: middle;
}

.referenceContentUploaderBox {
  background-color: #464b5b;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 25px;
  cursor: pointer;
  vertical-align: middle;
}

.referenceContentUploading {
  background-color: #393d50;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  padding: 10px 20px;
  vertical-align: middle;
  word-break: break-word;
}

.referenceLink {
  color: #eaedf3 !important;
  font-weight: normal;
}

.uploaderIcon {
  color: #9ea0a5;
  display: inline-block;
  line-height: 22px;
  margin: 10px;
}

.uploaderIconCheck {
  color: #15cd93;
  display: inline-block;
  line-height: 22px;
  margin: 10px;
}

.uploaderPlaceholder {
  color: #9ea0a5;
  font-size: 14px;
  line-height: 22px;
}

.uploaderButton {
  float: right;
}

.uploadProgress {
  height: 4px;
  width: 100%;
  border-radius: 2px;
}

.uploadingFilename {
  color: #eaedf3;
  margin-bottom: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.warning {
  height: 20px;
  width: 20px;
  margin-right: 9px;
}

.errorBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 258px;
  height: 38px;
  border-radius: 4px;
  background-color: #464a5c;
}

.errorMessage {
  font-size: 12px;
  color: #bac0cb;
}

.referenceDocumentTitle {
  float: right;
  word-break: break-word;
}

.referenceActionsDropDownContainer {
  float: right;
  margin-right: 15px;
  cursor: pointer;
}

.moreIcon {
  position: relative;
  top: -3px;
}

.searchInputField {
  margin: 0 auto;
  margin-top: 22px;
  background-color: #2c3041;
  border: none;
  color: #8a8e96;
  &::placeholder {
    color: #8a8e96;
    background-image: url("../../images/icons/svg/search.svg");
    background-repeat: no-repeat;
    background-position: right
  }
}

// Mozilla firefox specific styling for the input placeholder

@-moz-document url-prefix() {
  .searchInputField {
    &::placeholder {
      background-position-y: 50% !important;
      background-position-x: 96.5% !important;
    }
  }
}

.newReferenceHeader {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.46px;
  color: #cabdf6;
  text-transform: uppercase;
}

.chooseDoucmentText {
  font-size: 24px;
  font-weight: 500;
  margin-top: 8px;
}

.chooseDoucmentTextNew {
  font-size: 24px;
  font-weight: 500;
}

.newReferenceSidePanelHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0 auto;
  padding: 16px 3% 26px;
  width: 88%;

}

.newReferenceLinkContainer {
  background-color: #393d50;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  margin: 0 auto;
  vertical-align: middle;
  word-break: break-word;
  padding: 15px 3% 15px;
  width: 88%;
}

.addReferenceButton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  bottom: 0;
  background-color: #393d50;
  height: 100px;
  box-shadow: 0px 0px 0px #000, 0px -5px 5px rgba(50,50,50,0.45);
  :global(.button-primary) {
    width: 90%;
  }
}

.addReferenceButtonNew {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  bottom: 0;
  background-color: #393d50;
  height: 100px;
  box-shadow: 0px 0px 0px #000, 0px -5px 5px rgba(50,50,50,0.45);
  :global(.button-primary) {
    width: 90%;
  }
}

