.editUserContainer {
  background-color: #fbfbfb;
  margin-top: 30px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 5px;
}

.editContainer {
  padding: 0 20px;
}

.editActionButtons {
  padding: 20px;
}

.formLabel {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #9EA0A5;
}

.roleSelect {
  margin-top: 0.5rem;
}

.checkbox {
  margin: 0.2rem 0;
}

.UserEditFormSubtitle {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #9ea0a5;
  margin-bottom: 22px;
}

.permissionsTitle {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #9EA0A5;
}

.radiobuttonContainer {
  display: flex;
  max-width: 110px;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px; 
}

.libraryCheckbox {
  margin-top: 8px;
  margin-bottom: 8px;
}
.emailDisabled {
  background-color: rgba(226, 229, 237, 0.5) !important;
}

.resendInviteContainer {
  background-color: #393d50;
  padding: 18px 20px 18px 30px;
  color: white;
  margin-bottom: 33px;
  border-radius: 4.1px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.invitationText {
  display: flex;
}

.invitationButton {
  margin-left: auto;
  order: 2;
  white-space: nowrap;
}

.reset2fatitle {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #9EA0A5;
}

.reset2faContainer {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.reset2faContent {
  margin-top: 10px;
}

.reset2faContent :global(.button-primary__transparent) {
  width: 74px;
}

.notEnabled2fa {
  font-size: 12px;
  color: #6B6C6F;
  margin-top: 6px;
}
