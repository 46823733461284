.documentContainer {
  border: 1px solid #eaedf3;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
  width: 250px;
  margin: 15px;
  display: inline-block;
  float: left;
  overflow: hidden;
}

.documentContainerDark {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #2C3041;
  width: 250px;
  margin: 15px;
  display: inline-block;
  float: left;
  overflow: hidden;
}

.documentContainer:hover {
  border-color: #d4d9e2;
  box-shadow: 6px 6px 20px 4px #ececec;
  opacity: 1;
}

.documentContainerDark:hover {
  box-shadow: 6px 6px 20px 4px #2C3041;
  opacity: 1;
}

.documentContainer:hover .thumbnail {
  transform: scale(1.05);
  background-color: #fbfbfb;
}
.documentContainer:hover .nothumbnail {
  transform: scale(1.08);
  background-color: #fbfbfb
}

.heading {
  color: #3e3f42 !important;
  display: block;
  padding: 15px 15px 0px 15px;
  width: 220px;
  /*height: 95px;*/
  height: 45px;
  overflow: hidden;
}

.createdTime {
  position: static;
  bottom: 0px;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 15px;
  float: none;
  clear: none;
  color: #9ea0a5;
  font-size: 11px;
  font-weight: 500;
}

.thumbnail {
  background-color: #fbfbfb;
  display: block;
  width: 250px;
  height: 210px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-size: cover !important;
  transform: scale(1);
  transition: all 0.3s;
  animation: opac 0.8s;
}@keyframes opac{from{opacity:0} to{opacity:1}}
